body {
  margin: 0;
  font-family: 'Roboto', sans-serif !important; 
}

input, select, textarea {
  font-size: 14px !important;
}

.cardtitle
{
    margin-bottom:5px;
    font-size:14px;
    font-weight:700;
    color:#4e5559;
}

.body
{
  font-size: 13px;
  margin-bottom: 0px !important;
}

.cardtitle2
{
    margin-bottom:5px;
    font-size:15px;
    font-weight:500;
    color:#4e5559;
}

.marginTop {
  margin-top:20px;
}

.body2
{
  margin-bottom:5px;
  font-size:16px;
  text-transform: capitalize;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.card {
  width: 80%;
  margin-left: 10%;
  padding: 40px;
  background-color: #ffffff;
  border: 1px solid rgba(0, 34, 51, 0.1);
  box-shadow: 2px 4px 10px 0 rgba(0, 34, 51, 0.05), 2px 4px 10px 0 rgba(0, 34, 51, 0.05);
  border-radius: 0.15rem;
}

.card2 {
  width: 80%;
  margin-left: 10%;
  background-color: #ffffff;
  border: 1px solid rgba(0, 34, 51, 0.1);
  box-shadow: 2px 4px 10px 0 rgba(0, 34, 51, 0.05), 2px 4px 10px 0 rgba(0, 34, 51, 0.05);
  border-radius: 0.15rem;
}
.card12 {
  width: 100%;
  background-color: #ffffff;
  border: 1px solid rgba(0, 34, 51, 0.1);
  box-shadow: 2px 4px 10px 0 rgba(0, 34, 51, 0.05), 2px 4px 10px 0 rgba(0, 34, 51, 0.05);
  border-radius: 0.15rem;
}

.card7 {
  width: 100%;
  margin-left: 0%;
  background-color: #ffffff;
  border: 1px solid rgba(0, 34, 51, 0.1);
  box-shadow: 2px 4px 10px 0 rgba(0, 34, 51, 0.05), 2px 4px 10px 0 rgba(0, 34, 51, 0.05);
  border-radius: 0.15rem;
}

.displaymargin{
  margin-top: 20px;
}


.main_menu3 {
  display: inline-block;
}

.main_menu2 {
  display: none;
}

.mobile{
  display: none;
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active
{
  /* border: 2px solid #7a00ff !important; */
  color:#7a00ff !important;
  background-color: transparent !important; 
  border-bottom: 4px solid #7a00ff !important;
}

.nav-tabs
{
  border-bottom:'none' !important;
}

.header_btn
{
  margin-left: 10%;
  display:flex;
  width:80%;
  margin-right:10%;
  flex-direction:row;
  justify-content:space-between;
  margin-top:20px;
}
.header_btn2
{
  margin-left: 0%;
  display:flex;

  width:100%;
  margin-right:0%;
  flex-direction:row;
  justify-content:space-between;
  margin-bottom:20px;
  margin-top:20px;
}
.header1
{
  margin-top:30px;
}

.actionshow
{
  font-size: 13px;
  padding:2px 2px !important;
  border-radius: 5px;
}


.nav-link
{
  font-size: 15px;
  color: #001020 !important;

}

.displaytitle
{
  white-space: nowrap;
  width:200px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.title {
  font-size: 18px !important;
  color:#4e5559;
  text-transform: uppercase;
}
.padding{
  padding: 30px;
}
.action
{
  position:relative;
  top:-50px;
  right:10px;
}

.btnshow2{
  border:2px solid #FF7A00;
  color:#FF7A00 !important;
  text-decoration: none;
}
.btnshow
{
  text-decoration:none;
  border:none;
  color:#FF7A00 !important;
  text-decoration: none !important;
}


.actionbtn
{
  position:relative;
  text-decoration: none !important;
  
  top:20px;
  margin-right: 10px;
  right:0px;
}
.search {
  display: none;
}
@media screen and (max-width : 991px){
  .main_menu {
    display: none;
  }
  .main_menu3 {
    display: none;
  }
  .main_menu2{
    display: block;
  }
  .container{
    width: 100% !important;
    margin-left: auto;
    margin-right: auto !important;
    max-width: none !important;
  }
  body {
    
  }
   .desktop
   {
     display: none;
   }
  .header_btn
  {
    margin-left: 0%;
    display:flex;
    width:100%;
    flex-direction:row;
    justify-content:space-between;
    margin-top:20px;
  }
  .search {
    width: 100%;
    display: block;
    margin-top: 20px;
  }
  .padding{
    padding: 10px;
  }
  .displaymargin{
    margin-top: 0px;
  }
}

@media screen and (max-width : 768px){

  .header1 {
    margin-top: 30px;
  }

  .title {
    font-size: 14px !important;
    color:#4e5559;
    text-transform: uppercase;
  }
  .bodymobile{
    font-size: 13px;
    margin-bottom: 0px !important;

  }
  .body-wrapper .main-wrapper .page-wrapper .content-wrapper {
    padding: 7px;
   }
  .pixside{
    width:30% !important;
  }
  .mainpage{
    width:70% !important;
  }
  .actionshow
  {
    font-size: 13px;
    padding:2px 2px !important;
    border-radius: 5px;
  }
  .desktop
  {
    display: none;
  }
  .search {
    width: 100%;
    display: block;
    margin-top: 20px;
  }

  .bottomwrapper {
    position:fixed;
    bottom:0px;
    width:100%;
    z-index:4;
    background-color:#fff;
    padding:0px;
    height:60px;
    padding-bottom:7px;
    overflow-x: hidden;
    box-shadow: 2px 4px 10px 0 rgba(0, 34, 51, 0.05), 2px 4px 10px 0 rgba(0, 34, 51, 0.05);
  }
  .mobile
  {
    display: block;
  }
  .displaymargin{
    margin-top: 0px;
  }
  .padding{
    padding: 10px;
  }
  .main_menu {
    display: none;
  }
  .main_menu3 {
    display: none;
  }
  .main_menu2{
    display: block;
  }
  .mdc-drawer--dismissible.mdc-drawer--open{
    display: none;
  }
  .card, .card2, .card7{
    width: 100%;
    margin-left: 0%;
  }
  .header_btn
  {
    margin-left: 0%;
  }
  .container {
    max-width: auto;
  }
  i {
    font-size: 15px !important;
  }
  .btnlist
  {
    font-size: 13px;
    border-radius: 4px;
    margin:5px;
  }
  .action2
  {
   right: 20px;
  }

  .col-sm-2, .col-sm-10{
    width: auto !important;
    margin-left: 0px !important;
  }
  .bottomicon{
    margin-top:7px;
    text-align:center;
    color:#4e5559;
  }
  .bottomicon i {
    font-size:20px;
    color:#4e5559;
  }
  .bottomtext{
    margin-top:0px;
    font-size:13px;
    color:#4e5559;
  }
  .col-sm-6{
    margin-bottom: 20px !important;
  }
  .row{
    margin-bottom: 0px !important;
  }
}

@media (min-width: 576px){
.container {
    max-width: auto;
}
.col-sm-6{
  margin-bottom: 20px !important;
}
}